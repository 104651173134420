import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import includes from 'lodash/includes'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettingsService from '@/services/UserSettings'
import CityServices from '@/services/City'
import CountryService from '@/services/Country'
import ProvinceServices from '@/services/Province'
import StatusService from '@/services/Status'
import moment from 'moment'
import { data } from 'jquery'

@Component({})
export default class UserSettings extends BasePage {
  constructor() {
    super()
  }
  //verified user
  isOpenVerified: boolean = false
  documentsID: any = {}
  documentsSelfie: any = {}
  idStatus = null
  itemStatus: object[] = []
  status: any = {}
  imageID = null
  imageNameId = null
  imageSelfie = null
  imageNameSelfie = null

  user_is_agent: boolean = false
  user_is_partner: boolean = false

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = 'Search By Name'
  searchHintPhone: string = 'Search By Phone'
  searchHintEmail: string = 'Search By Email'
  searchIcon: string = 'search'

  searchInputEmail: string = ''
  searchInputTelp: string = ''
  // serchInputFilterMembership: string = ''

  filter: any = {
    // origin: [{ text: `Thailand`, value: 108},
    //          { text: `Japan`, value: 106},
    //          { text: `Singapore`, value: 187},
    //          { text: `Malaysia`, value: 128},
    //          { text: `Philippines`, value: 167},
    //          { text: `USA`, value: 222},
    //          { text: `China`, value: 44}],
    origin: [],
    from: {
      selected: null,
      keyword: null
    },
    label: 'Filter Membership',
    hint: 'Apply Membership',
    icon: 'mdi-filter-variant',
    loading: false
  }

  // Table
  tableHeaders: object[] = [
    {
      text: 'Member ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phone'
    },
    {
      text: 'Membership',
      align: 'center',
      sortable: false,
      value: 'membership'
    },
    {
      text: 'Last Login',
      align: 'left',
      sortable: true,
      value: 'last_login'
    },
    {
      text: 'Countries',
      align: 'center',
      sortable: false,
      value: 'country'
    },
    {
      text: 'Country Signup',
      align: 'center',
      sortable: false,
      value: 'country_signup'
    },
    {
      text: 'Verified Identity',
      align: 'center',
      sortable: false,
      value: 'verified_identity'
    },
    {
      text: 'Member Start Date',
      align: 'left',
      sortable: true,
      value: 'start_at'
    },
    {
      text: 'Member Expired',
      align: 'left',
      sortable: true,
      value: 'expired_at'
    },
    {
      text: 'Referred By',
      align: 'center',
      sortable: false,
      value: 'referral_name'
    },
    {
      text: 'Total Order',
      align: 'left',
      sortable: true,
      value: 'number_order'
    },
    {
      text: 'Total Products',
      align: 'left',
      sortable: true,
      value: 'total_my_products'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true
  settings: any = []
  // inactive table
  tableInactiveHeaders: object[] = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phone'
    },
    {
      text: 'Membership',
      align: 'center',
      sortable: false,
      value: 'membership'
    },
    {
      text: 'Last Login',
      align: 'left',
      sortable: true,
      value: 'last_login'
    },
    {
      text: 'Countries',
      align: 'center',
      sortable: false,
      value: 'country'
    },
    {
      text: 'Country Signup',
      align: 'center',
      sortable: false,
      value: 'country_signup'
    },
    {
      text: 'Verified Identity',
      align: 'center',
      sortable: false,
      value: 'verified_identity'
    },
    {
      text: 'Member Start Date',
      align: 'left',
      sortable: true,
      value: 'start_at'
    },
    {
      text: 'Member Expired',
      align: 'left',
      sortable: true,
      value: 'expired_at'
    },
    {
      text: 'Referred By',
      align: 'center',
      sortable: false,
      value: 'referral_name'
    },
    {
      text: 'Total Order',
      align: 'left',
      sortable: true,
      value: 'number_order'
    },
    {
      text: 'Total Products',
      align: 'left',
      sortable: true,
      value: 'total_my_products'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableInactiveItems: object[] = []
  tableTotalInactiveItems: string | number = 0
  tableRowsPerPageInactiveItems: number[] = [5, 10, 15, 25, 50, 100]
  tableInactivePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableInactiveLoading: boolean = true
  moreInfo: boolean = false
  itemCountry: any = []
  itemCountryDefault: any = []
  getCountryLoading: boolean = false

  //need approval
  tableApprovalHeaders: object[] = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phone'
    },
    {
      text: 'Membership',
      align: 'center',
      sortable: false,
      value: 'membership'
    },
    {
      text: 'Last Login',
      align: 'left',
      sortable: true,
      value: 'last_login'
    },
    {
      text: 'Countries',
      align: 'center',
      sortable: false,
      value: 'country'
    },
    {
      text: 'Country Signup',
      align: 'center',
      sortable: false,
      value: 'country_signup'
    },
    {
      text: 'Verified Identity',
      align: 'center',
      sortable: false,
      value: 'verified_identity'
    },
    {
      text: 'Member Start Date',
      align: 'left',
      sortable: true,
      value: 'start_at'
    },
    {
      text: 'Member Expired',
      align: 'left',
      sortable: true,
      value: 'expired_at'
    },
    {
      text: 'Referred By',
      align: 'center',
      sortable: false,
      value: 'referral_name'
    },
    {
      text: 'Total Order',
      align: 'left',
      sortable: true,
      value: 'number_order'
    },
    {
      text: 'Total Products',
      align: 'left',
      sortable: true,
      value: 'total_my_products'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableApprovalItems: object[] = []
  tableTotalApprovalItems: string | number = 0
  tableRowsPerPageApprovalItems: number[] = [5, 10, 15, 25, 50, 100]
  tableApprovalPagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableApprovalLoading: boolean = true

  //Verified
  tableVerifiedHeaders: object[] = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phone'
    },
    {
      text: 'Membership',
      align: 'center',
      sortable: false,
      value: 'membership'
    },
    {
      text: 'Last Login',
      align: 'left',
      sortable: true,
      value: 'last_login'
    },
    {
      text: 'Countries',
      align: 'center',
      sortable: false,
      value: 'country'
    },
    {
      text: 'Country Signup',
      align: 'center',
      sortable: false,
      value: 'country_signup'
    },
    {
      text: 'Verified Identity',
      align: 'center',
      sortable: false,
      value: 'verified_identity'
    },
    {
      text: 'Member Start Date',
      align: 'left',
      sortable: true,
      value: 'start_at'
    },
    {
      text: 'Member Expired',
      align: 'left',
      sortable: true,
      value: 'expired_at'
    },
    {
      text: 'Referred By',
      align: 'center',
      sortable: false,
      value: 'referral_name'
    },
    {
      text: 'Total Order',
      align: 'left',
      sortable: true,
      value: 'number_order'
    },
    {
      text: 'Total Products',
      align: 'left',
      sortable: true,
      value: 'total_my_products'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableVerifiedItems: object[] = []
  tableTotalVerifiedItems: string | number = 0
  tableRowsPerPageVerifiedItems: number[] = [5, 10, 15, 25, 50, 100]
  tableVerifiedPagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableVerifiedLoading: boolean = true

  //currency
  itemCurrency: any = []

  businessList: any = ['business', 'individual', 'partnership']
  membershipsList: any = []
  rolesList: any = []
  recomendationList: any = []
  recomendationListSub: any = []
  userDialog: boolean = false
  userDialogData: any = {}
  dateDialogOpen: boolean = false
  dateStartAtDialogOpen: boolean = false

  canOrderItems: any = [
    {
      text: 'Trading',
      value: 'trading'
    },
    {
      text: 'Forwarding',
      value: 'forwarding'
    }
  ]

  //is_partner is_agent
  radioButton: any = ''
  is_agent: number = 0
  is_partner: number = 0

  useAsEdit: boolean = false
  selectedEditId: string | number = null

  countries: any = []
  getCountriesLoading: boolean = false

  province: any = []
  getProvinceLoading: boolean = false

  city: any = []
  getCityLoading: boolean = false

  withdrawal_method: any = [
    {
      text: "Bank Transfer",
      value: "bank_transfer"
    }
  ]

  // Table List Products
  tableHeadersListProducts: object[] = [
    {
      text: 'ID Product',
      align: 'center',
      sortable: true,
      value: 'product_id'
    },
    {
      text: 'Image',
      align: 'center',
      sortable: false,
      value: 'image_url'
    },
    {
      text: 'Product Name',
      align: 'left',
      sortable: false,
      value: 'product_name'
    }
    // {
    //   text: 'Description',
    //   align: 'left',
    //   sortable: false,
    //   value: 'description'
    // }
  ]
  tableItemsListProducts: object[] = []
  tableTotalItemsListProducts: string | number = 0
  tableRowsPerPageItemsListProducts: number[] = [5, 10, 15, 25, 50, 100]
  tablePaginationListProducts: any = {
    sortBy: 'product_id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoadingListProducts: boolean = true
  //delete
  selectedDelete: any = null
  showDeleteModal: boolean = false

  selectedTab: string | number = 0

  showRestoreModal: boolean = false
  selectedRestore: string = ''

  contactModal: boolean = false
  contact: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }
  searchContact: Function
  contactDetail: any = {}
  userID: string = ''
  addContactModal: boolean = false
  contactDataDialog: any = {
    first_name: null,
    last_name: null,
    country: {
      items: [],
      keyword: null,
      loading: false,
      selected: null
    },
    province: {
      items: [],
      keyword: null,
      loading: false,
      selected: null
    },
    city: {
      items: [],
      keyword: null,
      loading: false,
      selected: null
    },
    subdistrict: null,
    address: null,
    postcode: null,
    email: null,
    company: null,
    phone: null
  }
  searchProvinces: Function
  searchCities: Function
  isEditContact: boolean = false
  havePrimaryContact: boolean = false
  primaryContactModal: boolean = false
  warningDelContact: boolean = false
  confirmContactDialog: boolean = false

  viaPlaceholder: any = 'https://via.placeholder.com/150';

  // data include global
  include: string = 'items,invoices,platforms,parents,childs,codes,verificationStatus,documentVerification,addresses,memberships'

  async mounted() {
    await this.getMemberships()
    await this.getStatus()
    await this.getUsers()
    await this.getInactiveUsers()
    await this.getApprovalUsers()
    await this.getVerifiedUsers()
    this.getAllTotalProducts()
    const dictionary: any = {
      en: {
        messages: {
          confirmed: () => 'This field must be exactly the same with Password field'
        }
      }
    }
    this.$validator.localize(dictionary)
    this.searchContact = debounce(this.getAllContact, 500)
    this.searchProvinces = debounce(this.getProvinces, 500)
    this.searchCities = debounce(this.getCities, 500)
  }

  @Watch('contact.keyword')
  async onChangedContact() {
    this.searchContact()
  }

  @Watch('contactDataDialog.province.keyword')
  async onChangedProvince() {
    this.searchProvinces()
  }

  @Watch('contactDataDialog.city.keyword')
  async onChangedCity() {
    this.searchCities()
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    await this.getUsers()
  }

  @Watch('tableInactivePagination', { deep: true })
  async onChangedInactive() {
    this.$vuetify.goTo(0)
    await this.getInactiveUsers()
  }

  @Watch('tableApprovalPagination', { deep: true })
  async onChangeApproval() {
    this.$vuetify.goTo(0)
    await this.getApprovalUsers()
  }

  @Watch('tableVerifiedPagination', { deep: true })
  async onChangeVerified() {
    this.$vuetify.goTo(0)
    await this.getVerifiedUsers()
  }

  // @Watch('searchInput', { deep: true })
  // async onChanged() {
  //   this.$vuetify.goTo(0)
  //   await this.getUsers()
  // }

  @Watch('tablePaginationListProducts', { deep: true })
  async onChangeListProduct() {
    await this.getListTotalProducts(this.id_user)
  }

  @Watch('filter.from.selected', { deep: true })
  async onFilterMembershipsChange() {
    await this.refreshSearch()
  }

  @Watch('radioButton', { deep: true })
  async onRadioButton() {
    if (this.radioButton === 'is_agent') {
      this.is_agent = 1
      this.is_partner = 0
    } else if (this.radioButton === 'is_partner') {
      this.is_agent = 0
      this.is_partner = 1
    }
  }

  async onMoveCurr() {
    if (this.userDialogData.default_shipping_to) {
      await this.getCurrency(this.userDialogData.default_shipping_to)
    }
  }

  async onClearFilter() {
    this.filter.from.selected = null
    await this.refreshSearch()
  }

  async refreshSearch(nameTab = null) {
    if (nameTab) {
      if (nameTab == 'active') {
        await this.getUsers()
      } else if (nameTab == 'inactive') {
        await this.getInactiveUsers()
      } else if (nameTab == 'needapproval') {
        await this.getApprovalUsers()
      } else if (nameTab == 'verified') {
        await this.getVerifiedUsers()
      }
    } else {
      if ((this.$refs as any).userTabs.selectedItems[0].$attrs.name === 'active') {
        await this.getUsers()
      } else if ((this.$refs as any).userTabs.selectedItems[0].$attrs.name === 'inactive') {
        await this.getInactiveUsers()
      } else if ((this.$refs as any).userTabs.selectedItems[0].$attrs.name === 'needapproval') {
        await this.getApprovalUsers()
      } else if ((this.$refs as any).userTabs.selectedItems[0].$attrs.name === 'verified') {
        await this.getVerifiedUsers()
      }
    }
  }

  clickTab(nameTab) {
    this.filter.from.selected = null
    this.searchInput = ''
    this.filter.from.selected = null
    this.searchInputEmail = ''
    this.filter.from.selected = null
    this.searchInputTelp = ''
    this.refreshSearch(nameTab)
  }

  //status
  async getStatus() {
    this.tableLoading = true
    const opts: any = {
      params: {
        'filter[status_type][is]': 6
      }
    }
    try {
      const response = await StatusService.getStatusUser(opts)
      this.itemStatus = []
      for (let status of response.data) {
        const statuses: any = {
          id: status.attributes.id,
          name: status.attributes.name
        }
        this.itemStatus.push(statuses)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getMemberships() {
    const opts: any = {
      params: {
        'page[limit]': 30
      }
    }

    try {
      const responseMemberships = await UserSettingsService.getMemberships(opts)
      // this.rolesList = responseRoles.data.data
      const dataMemberships = responseMemberships.data.data
      for (const memberships of dataMemberships) {
        const membership: any = {
          name: memberships.attributes.name,
          id: memberships.attributes.id
        }
        if (this.checkMember(membership) === false) {
          this.membershipsList.push(membership)
          this.filter.origin.push(membership)
        }
        const membershipsFilter: any = {
          text: memberships.attributes.name,
          value: memberships.attributes.name
          // value: memberships.attributes.id
        }
        if (this.checkMember(membershipsFilter) === false) {
          this.filter.origin.push(membershipsFilter)
        }
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  async getOneUsers(id) {
    try {
      const responseOneUser = await UserSettingsService.getOneUser(id)
      const dataRelationOneUser = responseOneUser.data.data.relationships.settings
      var settings = {
        default_shipping_to: '',
        currency: ''
      }
      // var default_shipping_to = ''
      // var currency = ''
      for (const settingsUser of dataRelationOneUser) {
        const dataIncludedOneUser = responseOneUser.data.included.settings[settingsUser.id].attributes
        if (dataIncludedOneUser.key === 'default_shipping_to') {
          settings.default_shipping_to = dataIncludedOneUser.value
        } else if (dataIncludedOneUser.key === 'currency') {
          settings.currency = dataIncludedOneUser.value
        }
      }
      this.settings = settings
    } catch (error) {
      this.catchHandler(error)
    }
  }

  async getCurrency(id) {
    try {
      const response = await CountryService.getOneCountry(id)
      this.userDialogData.currency = response.data.data.attributes.currency
    } catch (error) {
      this.catchHandler(error)
    }
  }

  checkMember(memberships) {
    const nonmember = [
      'Seller', 'Super Admin', 'Staff', 'QC', 'Accountant', 'Sales', 'Owner', 'Subscriber', 'Administrator', 'Agent'
    ]
    var check = false
    for (let non of nonmember) {
      if (non === memberships.name || non === memberships.text) {
        check = true
        break
      }
    }
    return check
  }

  openModalListProduct(id) {
    this.id_user = id
    this.getListTotalProducts(id)
  }

  async getListTotalProducts(id) {
    this.tableLoadingListProducts = true
    this.listProductsDialog = true
    const opts: any = {
      params: {
        'page[num]': this.tablePaginationListProducts.page,
        'page[limit]': this.tablePaginationListProducts.rowsPerPage,
        // sort: "-product_id",
        sort: this.tablePaginationListProducts.descending ? '-product_id' : 'product_id'
      }
    }
    try {
      this.tableItemsListProducts = []
      const response = await UserSettingsService.getTotalProducts(id, opts)
      const responseData = response.data.data
      for (const dataList of responseData) {
        var list = {
          product_id: dataList.attributes.product_id,
          image_url: dataList.attributes.image_url,
          product_name: dataList.attributes.product_name
          // description: dataList.attributes.description ? dataList.attributes.description : 'No description Provided'
        }
        // if (isEmpty(dataList.attributes.image_url)) {
        //   return 'No Image Available'
        // }
        // this.tableItemsListProducts.push(list)
        const findIndexMyProducts = findIndex(this.tableItemsListProducts, (tableList: any) => tableList.product_id === list.product_id)
        if (findIndexMyProducts === -1 || findIndexMyProducts === undefined) {
          this.tableItemsListProducts.push(list)
        } else {
          this.tableItemsListProducts[findIndexMyProducts] = list
        }
      }
      this.tableTotalItemsListProducts = response.data.meta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoadingListProducts = false
    }
  }

  async getAllTotalProducts() {
    this.tableLoading = true
    try {
      const response = await UserSettingsService.getAllTotalProducts()
      this.allTotalProducts = response.data.total
      // this.allTotalProducts.push()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  lookUserData(responseUserData, tableItems: Array<any> = []) {
    const responseData = responseUserData.data

    forEach(responseData, dataUser => {
      // var user_membership = ''
      // var id_membership = ''
      var codes = []
      var verificationStatus = 'unverified'
      var user = dataUser.attributes
      var documentsID = ''
      var documentsSelfie = ''

      if (!isEmpty(dataUser.relationships.documentVerification[0])) {
        const id_doc = dataUser.relationships.documentVerification[0].id
        const documentID = responseUserData.included.documentVerification[id_doc].attributes.url
        documentsID = documentID
      } else {
        documentsID = 'https://via.placeholder.com/150?text=No Image Available'
      }

      if (!isEmpty(dataUser.relationships.documentVerification[1])) {
        const id_doc = dataUser.relationships.documentVerification[1].id
        const documentID = responseUserData.included.documentVerification[id_doc].attributes.url
        documentsSelfie = documentID
      } else {
        documentsSelfie = 'https://via.placeholder.com/150?text=No Image Available'
      }

      if (!isEmpty(dataUser.relationships.verificationStatus)) {
        for (const status of dataUser.relationships.verificationStatus) {
          const id_status = responseUserData.included.verificationStatus[status.id].attributes.id
          const statusName = responseUserData.included.verificationStatus[status.id].attributes.name
          verificationStatus = statusName
          this.idStatus = id_status
        }
      } else {
        this.idStatus = 481
      }

      if (!isEmpty(dataUser.relationships.codes)) {
        for (const code of dataUser.relationships.codes) {
          // const id_code = code.id
          const code_name = responseUserData.included.codes[code.id].attributes.code
          // const marking_codes = {
          //   id: id_code,
          //   code: code_name
          // }
          codes.push(code_name)
          // detail_codes.push(marking_codes)
        }
      }

      user.codes = codes
      user.idStatus = this.idStatus
      user.verificationStatus = verificationStatus
      user.documentVerificationId = documentsID
      user.documentVerificationSelfie = documentsSelfie
      // user.membership = {}
      user.membershipData = []

      if ( !user.membership ) {
        user.membership = {
          name: '-',
          start_at: null,
          expired_at: null
        }
      } 

      for (const membership of dataUser.relationships.memberships) {
        const memberships = {
          name: responseUserData.included.memberships[membership.id].attributes.name,
          id: Number(membership.id)
        }
        // user.membership.push(Number(membership.id))
        user.membershipData.push(memberships)
      }

      var country_name = ''
      if (!isEmpty(dataUser.attributes.country_catalog_names)) {
        for (const country of dataUser.attributes.country_catalog_names) {
          country_name += `${country}<br/>`
        }
      } else {
        country_name = '-'
      }
      user.country_text = country_name
      if (user.country_signup) {
        user.country_signup_name = responseUserData.included.signupcountry[user.country_signup].attributes.name
      } else {
        user.country_signup_name = '-'
      }
      user.actionButton = false
      var extras = {}
      if (!isEmpty(user.extras)) {
        extras = {
          taxes_name: user.extras.taxes_name ? user.extras.taxes_name : '',
          taxes_id: user.extras.taxes_id ? user.extras.taxes_id : '',
          taxes_address: user.extras.taxes_address ? user.extras.taxes_address : '',
          type_of_bussines: user.extras.type_of_bussines ? user.extras.type_of_bussines : '',
          activated: user.extras.activated ? user.extras.activated : 0,
          first_name: user.extras.first_name ? user.extras.first_name : '',
          last_name: user.extras.last_name ? user.extras.last_name : '',
          withdraw_method: user.extras.withdraw_method ? user.extras.withdraw_method : '',
          withdraw_provider: user.extras.withdraw_provider ? user.extras.withdraw_provider : '',
          withdraw_account_name: user.extras.withdraw_account_name ? user.extras.withdraw_account_name : '',
          withdraw_account_number: user.extras.withdraw_account_number ? user.extras.withdraw_account_number : '',
          withdraw_swift_code: user.extras.withdraw_swift_code ? user.extras.withdraw_swift_code : '',
        }
      } else {
        extras = {
          taxes_name: '',
          taxes_id: '',
          taxes_address: '',
          type_of_bussines: '',
          activated: 0,
          first_name: '',
          last_name: '',
          withdraw_method: '',
          withdraw_provider: '',
          withdraw_account_name: '',
          withdraw_account_number: '',
          withdraw_swift_code: '',
        }
      }
      user.extras = extras
      if (user.membership.expired_at) {
        user.text_expired_at = moment(user.membership.expired_at).format('DD MMM YYYY')
      } else {
        user.text_expired_at = '-'
      }

      if (user.membership.start_at != null) {
        user.text_start_at = moment(user.membership.start_at).format('DD MMM YYYY')
      } else {
        user.text_start_at = '-'
      }

      if (user.last_login != null) {
        user.text_last_login = moment(user.last_login).fromNow()
      } else {
        user.text_last_login = '-'
      }

      if (includes(map(user.membershipData, 'name'), 'Agent')) {
        user.action_balance = `manage-agent/${user.id}/details`
      } else if (includes(map(user.membershipData, 'name'), 'Partner')) {
        user.action_balance = `manage-partner/${user.id}/details`
      } else {
        user.action_balance = `manage-user/${user.id}/balance-history`
      }

      if (user.is_partner === 1 || user.is_agent === 1) {
        user.isParent = true
      } else {
        user.isParent = false
      }

      // get addresses
      user.addresses = {}
      if (!isEmpty(dataUser.relationships.addresses)) {
        const id_address = dataUser.relationships.addresses[0].id
        user.addresses = responseUserData.included.addresses[id_address].attributes
      }

      //get refered by (handle to get referral)
      user.referral_name = '-'
      if (!isEmpty(dataUser.relationships.parents)) {
        const id_referral = dataUser.relationships.parents[0].id
        user.referral_name = responseUserData.included.parents[id_referral].attributes.name
      }

      tableItems.push(user)
    })
    return tableItems
  }

  async getUsers() {
    this.tableLoading = true
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        // sort: "-created_at",
        sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
        include: this.include
      }
    }
    if (this.searchInput != '') {
      this.filter.from.selected
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputEmail != '') {
      this.filter.from.selected
      opts.params = {
        ...opts.params,
        'filter[email][like]': this.searchInputEmail,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[email][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputTelp != '') {
      this.filter.from.selected
      opts.params = {
        ...opts.params,
        'filter[phone][like]': this.searchInputTelp,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[phone][like]'
      delete opts.params[textFilter]
    }
    if (this.filter.from.selected != null) {
      opts.params = {
        ...opts.params,
        'filter[memberships.name][like]': this.filter.from.selected
      }
    } else {
      var textFilter = 'filter[memberships.name][like]'
      delete opts.params[textFilter]
    }
    try {
      const response = await UserSettingsService.getUsers(opts)
      const responseMeta = response.data.meta
      const responseUserData = response.data

      this.tableItems = this.lookUserData(responseUserData, [])
      this.tableTotalItems = responseMeta.pagination.total
      this.tableLoading = false
      this.resetData()
    } catch (error) {
      this.catchHandler(error)
      console.log(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getInactiveUsers() {
    this.tableInactiveLoading = true
    const opts: any = {
      params: {
        'page[num]': this.tableInactivePagination.page,
        'page[limit]': this.tableInactivePagination.rowsPerPage,
        sort: this.tableInactivePagination.descending ? '-' + this.tableInactivePagination.sortBy : this.tableInactivePagination.sortBy,
        include: this.include
      }
    }
    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputEmail != '') {
      opts.params = {
        ...opts.params,
        'filter[email][like]': this.searchInputEmail,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[email][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputTelp != '') {
      opts.params = {
        ...opts.params,
        'filter[phone][like]': this.searchInputTelp,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[phone][like]'
      delete opts.params[textFilter]
    }
    if (this.filter.from.selected != null) {
      opts.params = {
        ...opts.params,
        'filter[memberships.name][like]': this.filter.from.selected
      }
    } else {
      var textFilter = 'filter[memberships.name][like]'
      delete opts.params[textFilter]
    }
    try {
      const response = await UserSettingsService.getInactiveUsers(opts)
      const responseMeta = response.data.meta
      const responseUserData = response.data
      this.tableInactiveItems = this.lookUserData(responseUserData, [])
      this.tableTotalInactiveItems = responseMeta.pagination.total
      this.tableInactiveLoading = false
      this.resetData()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableInactiveLoading = false
    }
  }

  async getApprovalUsers() {
    this.tableApprovalLoading = true
    const opts: any = {
      params: {
        'filter[verificationStatus.name][is]': 'need approval',
        'page[num]': this.tableApprovalPagination.page,
        'page[limit]': this.tableApprovalPagination.rowsPerPage,
        sort: this.tableApprovalPagination.descending ? '-' + this.tableApprovalPagination.sortBy : this.tableApprovalPagination.sortBy,
        include: this.include
      }
    }
    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputEmail != '') {
      opts.params = {
        ...opts.params,
        'filter[email][like]': this.searchInputEmail,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[email][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputTelp != '') {
      opts.params = {
        ...opts.params,
        'filter[phone][like]': this.searchInputTelp,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[phone][like]'
      delete opts.params[textFilter]
    }
    if (this.filter.from.selected != null) {
      opts.params = {
        ...opts.params,
        'filter[memberships.name][like]': this.filter.from.selected
      }
    } else {
      var textFilter = 'filter[memberships.name][like]'
      delete opts.params[textFilter]
    }
    try {
      const response = await UserSettingsService.getUsers(opts)
      const responseMeta = response.data.meta
      const responseUserData = response.data
      this.tableApprovalItems = this.lookUserData(responseUserData, [])
      this.tableTotalApprovalItems = responseMeta.pagination.total
      this.tableApprovalLoading = false
      this.resetData()
      // this.getApprovalUsers()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableApprovalLoading = false
    }
  }

  async getVerifiedUsers() {
    this.tableVerifiedLoading = true
    const opts: any = {
      params: {
        'filter[verificationStatus.name][is]': 'verified',
        'page[num]': this.tableVerifiedPagination.page,
        'page[limit]': this.tableVerifiedPagination.rowsPerPage,
        sort: this.tableVerifiedPagination.descending ? '-' + this.tableVerifiedPagination.sortBy : this.tableVerifiedPagination.sortBy,
        include: this.include
      }
    }
    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputEmail != '') {
      opts.params = {
        ...opts.params,
        'filter[email][like]': this.searchInputEmail,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[email][like]'
      delete opts.params[textFilter]
    }
    if (this.searchInputTelp != '') {
      opts.params = {
        ...opts.params,
        'filter[phone][like]': this.searchInputTelp,
        'filter[memberships.name][like]': 'Agent'
      }
    } else {
      var textFilter = 'filter[phone][like]'
      delete opts.params[textFilter]
    }
    if (this.filter.from.selected != null) {
      opts.params = {
        ...opts.params,
        'filter[memberships.name][like]': this.filter.from.selected
      }
    } else {
      var textFilter = 'filter[memberships.name][like]'
      delete opts.params[textFilter]
    }
    try {
      const response = await UserSettingsService.getUsers(opts)
      const responseMeta = response.data.meta
      const responseUserData = response.data
      this.tableVerifiedItems = this.lookUserData(responseUserData, [])
      this.tableTotalVerifiedItems = responseMeta.pagination.total
      this.tableVerifiedLoading = false
      this.resetData()
      // this.getVerifiedUsers()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableVerifiedLoading = false
    }
  }

  validatePassword() {
    if (this.userDialogData.password_confirmation == this.userDialogData.password) {
      return true
    } else {
      return false
    }
  }

  isRequiredLogo() {
    var check = false
    if (this.userDialogData.type_of_bussines === 'business') {
      if (this.userDialogData.company_logo_preview === this.viaPlaceholder) {
        if (this.userDialogData.company_logo) {
          check = true
        }
      }
    } else {
      check = true
    }
    return check
  }

  async addUser() {
    this.moreInfo = true
    await setTimeout(async () => {
      try {
        const validationResponse = await this.$validator.validateAll('userDialogScope')
        if (validationResponse && this.userDialogData.password && this.userDialogData.password_confirmation && this.userDialogData.street_address) {
          if (this.validatePassword()) {
            if (this.isRequiredLogo()) {
              this.userDialog = false
              var codes = []
              this.showLoading({ text: 'Creating...' })
              if (!isEmpty(this.userDialogData.marking_codes)) {
                for (const code of this.userDialogData.marking_codes) {
                  const marking_codes = {
                    code: code
                  }
                  codes.push(marking_codes)
                }
              }
              var payload_is_agent = 0
              var payload_is_partner = 0
              if (this.user_is_agent === true) {
                payload_is_agent = 1
              }
              if (this.user_is_partner === true) {
                payload_is_partner = 1
              }
              var dialogData = {
                is_agent: payload_is_agent,
                is_partner: payload_is_partner,
                referral_url: this.userDialogData.referral_url,
                referral_code: this.userDialogData.referral_code,
                shipping_list: this.userDialogData.shipping_list,
                // shipping_list_names: this.userDialogData.shipping_list_names,
                name: this.userDialogData.name,
                email: this.userDialogData.email,
                phone: this.userDialogData.phone,
                password: this.userDialogData.password,
                password_confirmation: this.userDialogData.password_confirmation,
                membership_ids: this.userDialogData.membership,
                marking_codes: codes,
                country_catalogs: this.userDialogData.country_selected,
                country_signup: this.userDialogData.country_signup,
                country_catalog_active: this.userDialogData.country_catalog_active,
                can_order: this.userDialogData.can_order,
                expired_at: this.userDialogData.member_expired_date,
                start_at: this.userDialogData.member_start_at_date,
                extras: {
                  taxes_address: this.userDialogData.taxes_address,
                  taxes_id: this.userDialogData.taxes_id,
                  taxes_name: this.userDialogData.taxes_name,
                  type_of_bussines: this.userDialogData.type_of_bussines,
                  withdraw_method: this.userDialogData.withdraw_method,
                  withdraw_provider: this.userDialogData.bank_name,
                  withdraw_account_name: this.userDialogData.bank_account_name,
                  withdraw_account_number: this.userDialogData.bank_account_number,
                  withdraw_swift_code: this.userDialogData.bank_swift_code
                },
                settings: [
                  {
                    key: 'currency',
                    value: this.userDialogData.currency
                  },
                  {
                    key: 'default_shipping_to',
                    value: this.userDialogData.default_shipping_to
                  }
                ],
                addresses: [
                  {
                    address_line: this.userDialogData.street_address,
                    country_id: this.userDialogData.country_signup,
                    province_id: this.userDialogData.province,
                    city_id: this.userDialogData.city,
                    subdistrict: this.userDialogData.subdistrict,
                    zipcode: this.userDialogData.postal
                  }
                ]
              }
              if (!dialogData.country_signup) {
                delete dialogData.country_signup;
              }
              if (dialogData.country_catalogs === null) {
                dialogData.country_catalogs = '[]'
              }
              if (!dialogData.addresses[0].address_line) {
                delete dialogData.addresses[0].address_line
              }

              const response = await UserSettingsService.createUser(dialogData)

              if (this.userDialogData.type_of_bussines === 'business') {
                await this.addCompany(response.data.data.attributes.id)
              }
              this.closeLoading()

              if (response.data.success) {
                this.showSnackbar({
                  text: 'User Created Successfully!',
                  color: 'green',
                  timeout: 2500
                })

              } else {
                if (response.data.message == "email was registered, please check") {
                  this.showSnackbar({
                    text: "The email address you have entered is already registered",
                    color: 'red',
                    timeout: 2500
                  })
                } else {
                  this.showSnackbar({
                    text: response.data.message,
                    color: 'red',
                    timeout: 2500
                  })
                }
              }

              this.tablePagination.sortBy = 'created_at'
              this.tablePagination.descending = true
              this.getUsers()

            } else {
              this.showSnackbar({
                text: "Company logo is required",
                color: 'red',
                timeout: 3500
              })
            }
          } else {
            this.showSnackbar({
              text: "Password doesn't match",
              color: 'red',
              timeout: 3500
            })
          }
        } else {
          this.showSnackbar({
            text: 'Please check all filed(s) requirements',
            color: 'red',
            timeout: 3500
          })
        }
      } catch (error) {
        if (error.response.data.code == '23000') {
          this.showSnackbar({
            text: "Marking Codes that you inserted already exist, please re-insert again in edit user menu!",
            color: 'red',
            timeout: 2500
          })
        } else {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 3500
          })
        }
      } finally {
        this.closeLoading()
      }
    }, 100)
  }

  openCreateDialog() {
    this.$validator.reset()
    this.resetData()
    this.getCountries()
    this.getProvince()
    this.getCity()
    this.getCountry()
    this.getCountriesDefault()
    this.getMemberships()
    this.useAsEdit = false
    this.userDialog = true
    // this.moreInfo = true
  }

  async openEditDialog(props: any) {
    await this.getOneUsers(props.item.id)
    this.getCountries()
    this.getProvince()
    this.getCity()
    this.getCountry()
    this.getCountriesDefault()
    this.getMemberships()

    if (props.item.start_at != null) {
      this.userDialogData = {
        name: props.item.name,
        email: props.item.email,
        referral_url: props.item.referral_url,
        referral_code: props.item.referral_code,
        shipping_list: props.item.shipping_list,
        default_shipping_to: parseInt(this.settings.default_shipping_to),
        currency: this.settings.currency,
        phone: props.item.phone,
        password: '',
        password_confirmation: '',
        membership: props.item.membership,
        marking_codes: props.item.codes,
        country_selected: props.item.country_catalogs,
        country_signup: props.item.country_signup,
        country_catalog_active: props.item.country_catalog_active,
        taxes_address: props.item.extras.taxes_address,
        taxes_id: props.item.extras.taxes_id,
        taxes_name: props.item.extras.taxes_name,
        type_of_bussines: props.item.extras.type_of_bussines,
        addresses_id: props.item.addresses.id,
        street_address: props.item.addresses.address_line,
        province: props.item.addresses.province_id,
        city: props.item.addresses.city_id,
        subdistrict: props.item.addresses.subdistrict,
        postal: props.item.addresses.zip_code,
        saved_country_signup: props.item.country_signup ? true : false,
        can_order: props.item.can_order,
        member_expired_date: props.item.expired_at ? this.toDatabaseDate(props.item.expired_at) : null,
        member_expired_dateFormatted: props.item.expired_at ? this.toFullDate(props.item.expired_at) : 'Member Expired Date',
        member_expired_tempDate: null,
        member_start_at_date: this.toDatabaseDate(props.item.start_at),
        member_start_at_dateFormatted: this.toFullDate(props.item.start_at),
        member_start_at_tempDate: null,
        withdraw_method: props.item.extras.withdraw_method,
        bank_name: props.item.extras.withdraw_provider,
        bank_account_number: props.item.extras.withdraw_account_number,
        bank_account_name: props.item.extras.withdraw_account_name,
        bank_swift_code: props.item.extras.withdraw_swift_code
      }
    } else {
      this.userDialogData = {
        name: props.item.name,
        email: props.item.email,
        referral_url: props.item.referral_url,
        referral_code: props.item.referral_code,
        shipping_list: props.item.shipping_list,
        default_shipping_to: parseInt(this.settings.default_shipping_to),
        currency: this.settings.currency,
        // shipping_list_names: props.item.shipping_list_names,
        phone: props.item.phone,
        password: '',
        password_confirmation: '',
        membership: props.item.membership,
        marking_codes: props.item.codes,
        country_selected: props.item.country_catalogs,
        country_signup: props.item.country_signup,
        country_catalog_active: props.item.country_catalog_active,
        taxes_address: props.item.extras.taxes_address,
        taxes_id: props.item.extras.taxes_id,
        taxes_name: props.item.extras.taxes_name,
        type_of_bussines: props.item.extras.type_of_bussines,
        addresses_id: props.item.addresses.id,
        street_address: props.item.addresses.address_line,
        province: props.item.addresses.province_id,
        city: props.item.addresses.city_id,
        subdistrict: props.item.addresses.subdistrict,
        postal: props.item.addresses.zip_code,
        saved_country_signup: props.item.country_signup ? true : false,
        can_order: props.item.can_order,
        member_expired_date: props.item.expired_at ? this.toDatabaseDate(props.item.expired_at) : null,
        member_expired_dateFormatted: props.item.expired_at ? this.toFullDate(props.item.expired_at) : 'Member Expired Date',
        member_expired_tempDate: null,
        member_start_at_date: null,
        member_start_at_dateFormatted: 'Member Start Date',
        member_start_at_tempDate: null,
        withdraw_method: props.item.extras.withdraw_method,
        bank_name: props.item.extras.withdraw_provider,
        bank_account_number: props.item.extras.withdraw_account_number,
        bank_account_name: props.item.extras.withdraw_account_name,
        bank_swift_code: props.item.extras.withdraw_swift_code
      }
    }
    if (props.item.is_agent === 1) {
      this.radioButton = 'is_agent'
      this.user_is_agent = true
    } else if (props.item.is_agent === 0) {
      this.radioButton = 'is_agent'
      this.user_is_agent = false
    }
    if (props.item.is_partner === 1) {
      this.radioButton = 'is_partner'
      this.user_is_partner = true
    }else if (props.item.is_partner === 0) {
      this.radioButton = 'is_partner'
      this.user_is_partner = false
    }
    this.selectedEditId = props.item.id
    this.useAsEdit = true
    this.userDialog = true
    if (!isEmpty(props.item.extras.taxes_name || props.item.extras.taxes_address ||
      props.item.extras.type_of_bussines || props.item.extras.taxes_id)) {
      this.moreInfo = true
    }
    if (props.item.company) {
      this.userDialogData.company_name = props.item.company.name
      // this.userDialogData.company_logo_preview = this.viaPlaceholder
      this.userDialogData.company_logo = null
      this.userDialogData.company_country = props.item.company.country_id
      this.userDialogData.company_id = props.item.company.id
      // setTimeout(() => {
      this.userDialogData.company_logo_preview = props.item.company.logo
      // }, 3000)
    } else {
      this.userDialogData.company_name = null
      this.userDialogData.company_logo_preview = this.viaPlaceholder
      this.userDialogData.company_logo = null
      this.userDialogData.company_country = null
      this.userDialogData.company_id = null
    }
  }

  async saveVerified() {
    try {
      this.showLoading({ text: 'Saving...' })
      if (this.status == 'unverified' && this.imageID != null) {
        let data = new FormData()
        data.append('document', this.imageID)
        data.append('name', this.imageNameId)
        data.append('additional_document', this.imageSelfie)
        data.append('additional_document_name', this.imageNameSelfie)
        await UserSettingsService.documents(this.selectedEditId, data)
      } else {
        var data = {
          relationships: [{
            type: 'user_verification',
            attributes: {
              id: this.idStatus
            }
          }]
        }
        await UserSettingsService.update(this.selectedEditId, data)
      }
      this.isOpenVerified = false
      this.showSnackbar({
        text: 'Identity Updated Successfully!',
        color: 'green',
        timeout: 2500
      })
      this.getUsers()
      this.getApprovalUsers()
      this.getVerifiedUsers()
    } catch (error) {
      this.catchHandler(error)
      this.isOpenVerified = false
    } finally {
      this.closeLoading()
    }
  }

  async updateUser() {
    this.moreInfo = true
    await setTimeout(async () => {
      try {
        const validationResponse = await this.$validator.validateAll('userDialogScope')
        if (validationResponse) {
          if (this.validatePassword()) {
            if (this.isRequiredLogo()) {
              this.userDialog = false
              var codes = []
              this.showLoading({ text: 'Saving...' })
              if (!isEmpty(this.userDialogData.marking_codes)) {
                for (const code of this.userDialogData.marking_codes) {
                  const marking_codes = {
                    code: code
                  }
                  codes.push(marking_codes)
                }
              }
              var payload_is_agent = 0
              var payload_is_partner = 0
              if (this.user_is_agent === true) {
                payload_is_agent = 1
              }
              if (this.user_is_partner === true) {
                payload_is_partner = 1
              }
              var dialogData = {
                is_agent: payload_is_agent,
                is_partner: payload_is_partner,
                referral_url: this.userDialogData.referral_url,
                referral_code: this.userDialogData.referral_code,
                shipping_list: this.userDialogData.shipping_list,
                // shipping_list_names: this.userDialogData.shipping_list_names,
                name: this.userDialogData.name,
                email: this.userDialogData.email,
                phone: this.userDialogData.phone,
                password: this.userDialogData.password,
                password_confirmation: this.userDialogData.password_confirmation,
                membership_ids: this.userDialogData.membership,
                marking_codes: codes,
                country_catalogs: this.userDialogData.country_selected,
                country_signup: this.userDialogData.country_signup,
                country_catalog_active: this.userDialogData.country_catalog_active,
                can_order: this.userDialogData.can_order,
                expired_at: this.userDialogData.member_expired_date,
                start_at: this.userDialogData.member_start_at_date,
                extras: {
                  taxes_address: this.userDialogData.taxes_address,
                  taxes_id: this.userDialogData.taxes_id,
                  taxes_name: this.userDialogData.taxes_name,
                  type_of_bussines: this.userDialogData.type_of_bussines,
                  withdraw_method: this.userDialogData.withdraw_method,
                  withdraw_provider: this.userDialogData.bank_name,
                  withdraw_account_name: this.userDialogData.bank_account_name,
                  withdraw_account_number: this.userDialogData.bank_account_number,
                  withdraw_swift_code: this.userDialogData.bank_swift_code
                },
                settings: [
                  {
                    key: 'currency',
                    value: this.userDialogData.currency
                  },
                  {
                    key: 'default_shipping_to',
                    value: this.userDialogData.default_shipping_to
                  }
                ],
                addresses: [
                  {
                    id: this.userDialogData.addresses_id,
                    address_line: this.userDialogData.street_address,
                    province_id: this.userDialogData.province,
                    city_id: this.userDialogData.city,
                    subdistrict: this.userDialogData.subdistrict,
                    zipcode: this.userDialogData.postal,
                    country_id: this.userDialogData.country_signup,
                  }
                ]
              }
              if (isEmpty(dialogData.password) || isEmpty(dialogData.password_confirmation)) {
                delete dialogData.password
                delete dialogData.password_confirmation
              }
              if (!dialogData.country_signup) {
                delete dialogData.country_signup
              }
              if (dialogData.country_catalogs === null) {
                dialogData.country_catalogs = '[]'
              }
              await UserSettingsService.update(this.selectedEditId, dialogData)
              if (this.userDialogData.type_of_bussines === 'business') {
                if (this.userDialogData.company_id) {
                  await this.updateCompany(this.selectedEditId)
                } else {
                  await this.addCompany(this.selectedEditId)
                }
              }
              this.closeLoading()
              this.showSnackbar({
                text: 'User Updated Successfully!',
                color: 'green',
                timeout: 2500
              })
              if (this.userDialogData.referral_url === null) {
                this.moreInfo = false
              }
              this.getUsers()
              this.useAsEdit = false
            } else {
              this.showSnackbar({
                text: 'Company logo is required',
                color: 'red',
                timeout: 3500
              })
            }
          } else {
            this.showSnackbar({
              text: "Password doesn't match",
              color: 'red',
              timeout: 3500
            })
          }
        } else {
          this.showSnackbar({
            text: 'Please check all filed(s) requirements',
            color: 'red',
            timeout: 3500
          })
        }
      } catch (error) {
        if (error.response.data.code == '23000') {
          this.showSnackbar({
            text: 'Marking Codes that you inserted already exist!',
            color: 'red',
            timeout: 3500
          })
        } else {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 3500
          })
        }
      } finally {
        this.closeLoading()
      }
    }, 100)
  }

  resetData() {
    this.userDialogData = {
      name: '',
      email: '',
      referral_url: null,
      referral_code: null,
      shipping_list: [],
      // shipping_list_names: [],
      phone: '',
      password: '',
      password_confirmation: '',
      membership: [],
      marking_codes: '',
      country_selected: [],
      country_signup: '',
      country_catalog_active: '',
      taxes_address: '',
      taxes_id: '',
      taxes_name: '',
      type_of_bussines: '',
      company_name: '',
      company_country: null,
      company_logo_preview: this.viaPlaceholder,
      company_logo: null,
      default_shipping_to: null,
      saved_country_signup: false,
      can_order: null,
      member_expired_date: null,
      member_expired_dateFormatted: null,
      member_expired_tempDate: null,
      member_start_at_date: null,
      member_start_at_dateFormatted: null,
      member_start_at_tempDate: null,
      street_address: '',
      province: '',
      city: '',
      subdistrict: '',
      postal: '',
      withdraw_method: '',
      bank_name: '',
      bank_account_number: '',
      bank_account_name: '',
      bank_swift_code: ''
    }
    this.radioButton = ''
    this.user_is_agent = false
    this.user_is_partner = false
  }

  searchFilterChanged(newData: any) {
    this.tablePagination.page = 1
    this.searchFilterParams = newData
    this.getUsers()
  }

  async getCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.countries = []
      for (const country of responseData) {
        this.countries.push(country.attributes)
      }
      this.countries = sortBy(this.countries, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  async getProvince() {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10, // Calling all country. Reducing constant filter request to the server
          'filter[name][like]': '',
          'filter[country_id][is]': this.userDialogData.country_signup
        }
      }
      const response = await ProvinceServices.getProvinces(opts)
      const responseData = response.data.data
      this.province = []
      for (const province of responseData) {
        this.province.push(province.attributes)
      }
      this.province = sortBy(this.province, 'name')

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  async getCity() {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[province_id][is]': this.userDialogData.province
        }
      }
      const response = await CityServices.getCities(opts)
      const responseData = response.data.data
      this.city = []
      for (const city of responseData) {
        this.city.push(city.attributes)
      }
      this.city = sortBy(this.city, 'name')

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  onSelectDateInput(event) {
    this.userDialogData.member_expired_tempDate = event
  }

  onSelectDateCancel() {
    this.dateDialogOpen = false
  }

  onSelectDateDone() {
    if (this.userDialogData.member_expired_tempDate) {
      this.userDialogData.member_expired_date = this.userDialogData.member_expired_tempDate
      this.userDialogData.member_expired_dateFormatted = this.toFullDate(
        this.userDialogData.member_expired_tempDate
      )
    }
    this.dateDialogOpen = false
  }

  onSelectDateStratAtInput(event) {
    this.userDialogData.member_start_at_tempDate = event
  }

  onSelectDateStartAtCancel() {
    this.dateStartAtDialogOpen = false
  }

  onSelectDateStartAtDone() {
    if (this.userDialogData.member_start_at_tempDate) {
      this.userDialogData.member_start_at_date = this.userDialogData.member_start_at_tempDate
      this.userDialogData.member_start_at_dateFormatted = this.toFullDate(
        this.userDialogData.member_start_at_tempDate
      )
    }
    this.dateStartAtDialogOpen = false
  }

  cancelUserDialog() {
    this.$validator.reset()
    if ((this.$refs as any).resetData) {
      ; (this.$refs as any).resetData.value = ''
    }
    this.userDialog = false
    this.radioButton = ''
    this.moreInfo = false
  }

  deleteTemporary(props) {
    this.selectedDelete = props.item
    this.showDeleteModal = true
  }

  async deleteUsers() {
    try {
      const payload = {
        id: [this.selectedDelete.id]
      }
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await UserSettingsService.deleteUsers(this.selectedDelete.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      await this.getUsers()
      await this.getInactiveUsers()
      await this.getApprovalUsers()
      await this.getVerifiedUsers()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openRestoreModal(id) {
    this.selectedRestore = id
    this.showRestoreModal = true
  }

  async restoreUser() {
    try {
      this.showRestoreModal = false
      this.showLoading({ text: 'Restoring...' })
      const payload = {
        restore_ids: [this.selectedRestore]
      }
      const response = await UserSettingsService.restoreUser(payload)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      await this.getUsers()
      await this.getInactiveUsers()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async openContactModal(id) {
    this.userID = id
    this.contactModal = true
    await this.getAllContact()
  }

  closeContactModal() {
    this.contactModal = false
    this.contact.selected = null
    this.contactDetail = {}
    this.userID = ''
  }

  async getAllContact() {
    this.contact.loading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          'filter[first_name][like]': ''
        }
      }
      if (!isEmpty(this.contact.keyword)) {
        var textFilter = 'filter[first_name][like]'
        opts.params[textFilter] = this.contact.keyword
      } else {
        var textFilter = 'filter[first_name][like]'
        delete opts.params[textFilter]
      }
      const response = await UserSettingsService.getContactUser(this.userID, opts)
      const responseData = response.data.data
      this.contact.item = []
      let check = false
      for (const contact of responseData) {
        const item = {
          ...contact.attributes
        }
        if (item.is_primary === 1) {
          check = true
          item.name = contact.attributes.first_name + ' ' + contact.attributes.last_name + ' (Primary Contact)'
        } else {
          item.name = contact.attributes.first_name + ' ' + contact.attributes.last_name
        }
        // item.name = contact.attributes.first_name
        this.contact.item.push(item)
      }
      this.havePrimaryContact = check
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.contact.loading = false
    }
  }

  async getSelectedContact() {
    try {
      const response = await UserSettingsService.getOneContact(this.userID, this.contact.selected)
      this.contactDetail = response.data.data.attributes
    } catch (error) {
      this.catchHandler(error)
    }
  }

  async getCountryByIso(isocode) {
    const opts: any = {
      params: {
        'filter[iso_code_2][is]': isocode
      }
    }
    const response = await CountryService.getCountries(opts)
    return response.data.data[0].attributes
  }

  async getProvinces() {
    try {
      this.contactDataDialog.province.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.contactDataDialog.country.selected.id
        }
      }
      if (!isEmpty(this.contactDataDialog.province.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.contactDataDialog.province.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await ProvinceServices.getProvinces(opts)
      const responseData = response.data.data
      // this.province = []
      // for (const province of responseData) {
      //   this.province.push(province.attributes)
      // }
      this.contactDataDialog.province.items = []
      for (const province of responseData) {
        this.contactDataDialog.province.items.push({
          name: province.attributes.name,
          id: province.attributes.id
        })
      }
      this.contactDataDialog.province.items = sortBy(this.contactDataDialog.province.items, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.contactDataDialog.province.loading = false
    }
  }

  async getProvinceByName(name) {
    const opts: any = {
      params: {
        'filter[name][is]': name
      }
    }
    const response = await ProvinceServices.getProvinces(opts)
    return response.data.data[0].attributes
  }

  async getCities() {
    try {
      this.contactDataDialog.city.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[province_id][is]': this.contactDataDialog.province.selected.id
        }
      }
      if (!isEmpty(this.contactDataDialog.city.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.contactDataDialog.city.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CityServices.getCities(opts)
      const responseData = response.data.data
      this.city = []
      for (const city of responseData) {
        this.city.push(city.attributes)
      }
      this.contactDataDialog.city.items = []
      for (const city of responseData) {
        this.contactDataDialog.city.items.push({
          name: city.attributes.name,
          id: city.attributes.id
        })
      }
      this.contactDataDialog.city.items = sortBy(this.contactDataDialog.city.items, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.contactDataDialog.city.loading = false
    }
  }

  async getCityByName(name) {
    const opts: any = {
      params: {
        'filter[name][is]': name
      }
    }
    const response = await CityServices.getCities(opts)
    return response.data.data[0].attributes
  }

  async openAddContactModal() {
    this.contactModal = false
    await this.getCountries()
    this.isEditContact = false
    this.addContactModal = true
  }

  resetContactData() {
    this.$validator.reset()
    this.contactDataDialog = {
      first_name: null,
      last_name: null,
      country: {
        items: [],
        keyword: null,
        loading: false,
        selected: null
      },
      province: {
        items: [],
        keyword: null,
        loading: false,
        selected: null
      },
      city: {
        items: [],
        keyword: null,
        loading: false,
        selected: null
      },
      subdistrict: null,
      address: null,
      postcode: null,
      email: null,
      company: null,
      phone: null
    }
  }

  closeAddContactModal() {
    this.resetContactData()
    this.addContactModal = false
    this.contactModal = true
  }

  async addContact() {
    try {
      const validationResponse = await this.$validator.validateAll('contactDialogScope')
      if (validationResponse) {
        this.addContactModal = false
        this.contactModal = true
        this.showLoading({ text: 'Saving...' })
        const payload = {
          is_primary: 0,
          first_name: this.contactDataDialog.first_name,
          last_name: this.contactDataDialog.last_name,
          company: this.contactDataDialog.company,
          country: this.contactDataDialog.country.selected.iso_code_2,
          city_id: this.contactDataDialog.city.selected.id,
          city_name: this.contactDataDialog.city.selected.name,
          email: this.contactDataDialog.email,
          province: this.contactDataDialog.province.selected.name,
          address: this.contactDataDialog.address,
          subdistrict: this.contactDataDialog.subdistrict,
          postcode: this.contactDataDialog.postcode,
          phone: this.contactDataDialog.phone
        }
        const response = await UserSettingsService.setContact(this.userID, payload)
        this.closeLoading()
        this.showSnackbar({
          text: response.status === 200 ? 'Contact Saved Successfully!' : 'Contact Failed to Save',
          color: response.status === 200 ? 'green' : 'red',
          timeout: 2500
        })
        this.resetContactData()
        await this.getAllContact()
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async openEditContact() {
    const country = await this.getCountryByIso(this.contactDetail.country)
    const province = await this.getProvinceByName(this.contactDetail.province)
    const city = await this.getCityByName(this.contactDetail.city_name)
    this.contactDataDialog.first_name = this.contactDetail.first_name
    this.contactDataDialog.last_name = this.contactDetail.last_name
    this.contactDataDialog.country.selected = country
    this.contactDataDialog.province.selected = province
    this.contactDataDialog.province.items.push(province)
    this.contactDataDialog.city.selected = city
    this.contactDataDialog.city.items.push(city)
    this.contactDataDialog.subdistrict = this.contactDetail.subdistrict
    this.contactDataDialog.address = this.contactDetail.address
    this.contactDataDialog.postcode = this.contactDetail.postcode
    this.contactDataDialog.phone = this.contactDetail.phone
    this.contactDataDialog.email = this.contactDetail.email
    this.contactDataDialog.company = this.contactDetail.company
    this.contactModal = false
    this.isEditContact = true
    this.addContactModal = true
    await this.getCountries()
  }

  async updateContact() {
    this.addContactModal = false
    this.contactModal = true
    const payload = {
      is_primary: this.contactDetail.is_primary,
      first_name: this.contactDataDialog.first_name,
      last_name: this.contactDataDialog.last_name,
      company: this.contactDataDialog.company,
      country: this.contactDataDialog.country.selected.iso_code_2,
      city_id: this.contactDataDialog.city.selected.id,
      city_name: this.contactDataDialog.city.selected.name,
      email: this.contactDataDialog.email,
      province: this.contactDataDialog.province.selected.name,
      address: this.contactDataDialog.address,
      subdistrict: this.contactDataDialog.subdistrict,
      postcode: this.contactDataDialog.postcode,
      phone: this.contactDataDialog.phone
    }
    await this.sendUpdateContact(this.contactDetail.id, payload)
  }

  async setPrimaryContact() {
    if (this.havePrimaryContact) {
      this.primaryContactModal = true
    } else {
      const payload = {
        is_primary: 1,
        first_name: this.contactDetail.first_name,
        last_name: this.contactDetail.last_name,
        company: this.contactDetail.company,
        country: this.contactDetail.country,
        city_id: this.contactDetail.city_id,
        city_name: this.contactDetail.city_name,
        email: this.contactDetail.email,
        province: this.contactDetail.province,
        address: this.contactDetail.address,
        subdistrict: this.contactDetail.subdistrict,
        postcode: this.contactDetail.postcode,
        phone: this.contactDetail.phone
      }
      await this.sendUpdateContact(this.contactDetail.id, payload)
    }
  }

  async delPrimaryContact() {
    const payload = {
      is_primary: 0,
      first_name: this.contactDetail.first_name,
      last_name: this.contactDetail.last_name,
      company: this.contactDetail.company,
      country: this.contactDetail.country,
      city_id: this.contactDetail.city_id,
      city_name: this.contactDetail.city_name,
      email: this.contactDetail.email,
      province: this.contactDetail.province,
      address: this.contactDetail.address,
      subdistrict: this.contactDetail.subdistrict,
      postcode: this.contactDetail.postcode,
      phone: this.contactDetail.phone
    }
    await this.sendUpdateContact(this.contactDetail.id, payload)
  }

  async sendUpdateContact(id, data) {
    try {
      this.showLoading({ text: 'Saving...' })
      const response = await UserSettingsService.updateContact(this.userID, id, data)
      this.showSnackbar({
        text: response.status === 200 ? 'Contact Saved Successfully!' : 'Contact Failed to Save',
        color: response.status === 200 ? 'green' : 'red',
        timeout: 2500
      })
      await this.getAllContact()
      await this.getSelectedContact()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  confirmDelContact() {
    if (this.contactDetail.is_primary === 1) {
      this.warningDelContact = true
    } else {
      this.confirmContactDialog = true
    }
  }

  async deleteContact() {
    try {
      this.confirmContactDialog = false
      this.showLoading({ text: 'Deleting...' })
      const response = await UserSettingsService.deleteContact(this.userID, this.contactDetail.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.contact.selected = null
      this.contactDetail = {}
      await this.getAllContact()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openMoreInfo() {
    this.moreInfo = !this.moreInfo
  }

  async getCountry() {
    this.getCountryLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemCountry = []
      for (const country of responseData) {
        this.itemCountry.push(country.attributes)
      }
      this.itemCountry = sortBy(this.itemCountry, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountryLoading = false
    }
  }

  async getCountriesDefault() {
    this.getCountryLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemCountryDefault = []
      for (const country of responseData) {
        this.itemCountryDefault.push(country.attributes)
      }
      this.itemCountryDefault = sortBy(this.itemCountryDefault, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountryLoading = false
    }
  }

  changeLogo(e) {
    const file = e.target.files[0]
    this.userDialogData.company_logo = file
  }

  async addCompany(iduser) {
    try {
      let configfile = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
          Identifier: 'manage'
        }
      }
      let data = new FormData()
      data.append('logo', this.userDialogData.company_logo)
      data.append('name', this.userDialogData.company_name)
      data.append('country_id', this.userDialogData.company_country)
      data.append('members[]', iduser)

      await UserSettingsService.addCompany(data, configfile)
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async updateCompany(iduser) {
    try {
      let configfile = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
          Identifier: 'manage'
        }
      }
      let data = new FormData()
      data.append('logo', this.userDialogData.company_logo)
      data.append('name', this.userDialogData.company_name)
      data.append('country_id', this.userDialogData.company_country)

      await UserSettingsService.updateCompany(this.userDialogData.company_id, data, configfile)
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async openVerified(props) {
    this.documentsID = props.item.documentVerificationId
    this.documentsSelfie = props.item.documentVerificationSelfie
    this.idStatus = props.item.idStatus
    this.status = props.item.verificationStatus
    if (props.item.verificationStatus != 'need approval') {
      this.itemStatus.push({
        name: props.item.verificationStatus,
        id: props.item.idStatus
      })
    }
    this.selectedEditId = props.item.id
    this.isOpenVerified = true
  }

  imageid(e) {
    const file = e.target.files[0]
    if ((file.size / 1024) < 500) {
      this.imageID = file
      this.imageNameId = file.name
    } else {
      this.imageID = null
      this.imageNameId = null
        ; (this.$refs as any).resetData.value = ''
      this.showSnackbar({
        text: 'File size exceeds 500 KB',
        color: 'red',
        timeout: 2000
      })
    }
  }

  imageselfie(e) {
    const file = e.target.files[0]
    if ((file.size / 1024) < 500) {
      this.imageSelfie = file
      this.imageNameSelfie = file.name
    } else {
      this.imageSelfie = null
      this.imageNameSelfie = null
        ; (this.$refs as any).resetData.value = ''
      this.showSnackbar({
        text: 'File size exceeds 500 KB',
        color: 'red',
        timeout: 2000
      })
    }
  }
}
